.main {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/mobile-backgorund.svg);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Nunito';
}

@media (min-width: 960px) {
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/desktop-background.svg);
    background-size: cover;
    background-repeat: no-repeat;
  }
}
