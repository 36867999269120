.title {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.LogoContainer {
  width: 15rem;
  margin-bottom: 20px;
}

.InputContainer {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  row-gap: 20px;
  margin-bottom: 20px;
  font-family: 'Nunito';
}

::placeholder {
  color: #6d6e71;
}

.ConsentTerm {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 5px;
}

.checkBox {
  height: 25px;
  width: 25px;
  margin-top: 11px;
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
}

.hostSwitch {
  display: flex;
  justify-items: center;
  align-items: center;
  font-family: 'Nunito';
}
.hostText {
  margin-left: 0px;
  font-size: 1rem;
}

.continueButton {
  padding: 0.5rem 0.5rem;
  color: #fff;
  background-color: var(--brand-blue-100);
  border-radius: 20px;
  border: none;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 25%);
  font-size: 18px;
  margin-left: 10px;
  font-family: 'Nunito';
  width: 140px;
}

.continueButton:disabled {
  background-color: var(--brand-blue-50);
}

.continueButton:hover:enabled {
  background: var(--brand-blue-200);
}
