:root {
  --brand-color: #292929;
  --brand-text-color: #ffffff;
  --brand-color-dark-mode: #fafafa;
  --brand-text-color-dark-mode: #292929;

  --brand-white: #ffffff;

  --brand-yellow-50: #ffdf7c;

  --brand-blue-50: #74acdd;
  --brand-blue-100: #1566c0;
  --brand-blue-200: #0e548e;

  --brand-green-50: #17a385;
  --brand-green-100: #148069;

  --brand-black: #000000;
  --brand-text-black: #42454a;
  --brand-smoke-shadow: #00000033;
}
